<template>
  <div class="premium-page">
    <div class="container">
      <main v-if="!packageSelectedFlag" class="page-content">
        <div class="title">Get it now</div>
        <div class="subtitle">Choose the best price for yourself<br/>
          Start Losing Weight with an Intermittent Fasting</div>
        <div class="packages-wrapper">
          <div class="packages-list" ref="packagesList">
            <div
                v-for="product in computedPackagesList"
                :key="product.id"
                class="package"
                :class="[
                   {popular: defaultPackages[group] === product.packageKey},
                   {selected: selectedPlan === product.packageKey}

                    ]"
                @click="onPackageClick(product)"
            >
              <div class="label">most popular</div>
              <div class="checkbox"></div>
              <div class="package-info-wrapper">
                <div class="package-info">
                  <div class="name">{{ getPackageProperty(product, 'name') }}</div>
                </div>
                <div class="package-price">
                  <span v-if="getPackageProperty(product, 'dollarsPerDay')">
                    {{ `${product.price.currencySymbol}${product.price.amount}` }}
                  </span>
                  <span class="additional-text">{{ getPackageProperty(product, 'desc') }}</span>
                </div>
              </div>
              <div class="current-week-price">
                <img v-if="defaultPackages[group] === product.packageKey" src="@/assets/img/icons/plate-green.svg"
                     alt="">
                <img v-else src="@/assets/img/icons/plate-g.svg" alt="">
                <div class="info">
                  <div>{{ product.price.currencySymbol }}</div>
                  <div class="dollars-per-day">
                    {{ getPackageProperty(product, 'dollarsPerDay') || product.price.prefixAmount }}
                  </div>
                  <div class="per-day-wrapper" :class="{'flex-start': !getPackageProperty(product, 'dollarsPerDay')}">
                    <div class="coins-per-day">
                      {{ getPackageProperty(product, 'coinsPerDay') || product.price.suffixAmount }}
                    </div>
                    <div v-if="getPackageProperty(product, 'dollarsPerDay')" class="per-day">per day</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="web-get-now-btn">
            <button
                class="membership-btn"
                :disabled="!trialAccepted"
                @click="onGetNowBtnClick"
            >
              {{ mainBtnText }}
            </button>
          </div>
          <label class="accepting-conditions_container">
            <input type="checkbox" v-model="trialAccepted">
            <span class="checkmark"></span>
            <span class="acceptingConditions">
              $0.99 trial for 3 days, then auto renewable subscription plan. Subscription could be canceled anytime
              <a href="https://app-fasting.com/refund-policy" target="_blank">Refund Policy</a>

              <p>
                By continuing you agree that if you don’t cancel at least 24 hours prior to the end of the 3-days trial,
                you will automatically be charged due to subscription plan chosen above every mentioned plan’s period
                until you cancel it.
              </p>
            </span>
          </label>
        </div>
      </main>
      <membership-payment-form
          v-else
          :selectedPackage="selectedPackage"
          :full-package="computedSelectedFullPackage"
          :plan-name="computedAmplitudePackageEventName"
          @back="selectedPackage = null"
      />
    </div>
  </div>
</template>

<script>
import {store} from '@/store';
import MembershipPaymentForm from '@/components/MembershipPaymentForm';

export default {
  name: 'Premium',
  components: {
    MembershipPaymentForm
  },
  data() {
    return {
      selectedPlan: 'callrecorder:6m',
      loading: true,


      trialAccepted: false,
      group: "A",   // A / B
      availablePackages: {
        A: ['callrecorder:1w', 'callrecorder:1m', 'callrecorder:6m'],
        B: ['callrecorder:1w', 'callrecorder:1m', 'callrecorder:99y']
      },
      defaultPackages: {
        A: 'callrecorder:6m',
        B: 'callrecorder:99y'
      },
      packagesList: [],
      payload: {},
      selectedPackage: null,
      packageSelectedFlag: false,
      packagesAdditionalInfo: [
        {
          name: '7-Day Plan',
          code: 'Weekly',
          period: '1w',
          key: 'callrecorder:1w',
          order: 2,
          desc: 'after trial',
          dollarsPerDay: '2',
          coinsPerDay: '14'
        },
        {
          name: '1-Month Plan',
          code: 'Monthly',
          period: '1m',
          key: 'callrecorder:1m',
          order: 0,
          desc: 'after trial',
          dollarsPerDay: '0',
          coinsPerDay: '99'
        },
        {
          name: '6-Months Plan',
          code: '6Months',
          period: '6m',
          key: 'callrecorder:6m',
          order: 1,
          desc: 'after trial',
          dollarsPerDay: '0',
          coinsPerDay: '39'
        },
        {
          name: 'Lifetime',
          code: 'Lifetime',
          period: '99y',
          key: 'callrecorder:99y',
          order: 1,
          desc: 'one-time payment',
        }
      ],
      mockProducts: {
        "statusCode": 0,
        "payload": {
          "appId": "11EC0B1BED96E707A8E252732F4B14CD",
          "defPackageId": "3C7A1590E966FC480933251033E40D61",
          "bestPackageId": "00F92D81B6155AC2ED8F46EE2983988E",
          "packageList": [
            {
              "type": "membership",
              "id": "00F92D81B6155AC2ED8F46EE2983988E",
              "packageKey": "callrecorder:trial_1w",
              "period": "3d",
              "price": {
                "amount": "0.99",
                "prefixAmount": "0",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 99
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "27B097EB1DEF013C665E54A3D7BB7E80",
                "packageKey": "callrecorder:1w",
                "period": "1w",
                "price": {
                  "amount": "14.99",
                  "prefixAmount": "14",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 1499
                },
                "payPermissionType": 1
              }
            },
            {
              "type": "membership",
              "id": "F2F9E28EE0F43AD106D9C67C2294E27C",
              "packageKey": "callrecorder:trial_1m",
              "period": "3d",
              "price": {
                "amount": "0.99",
                "prefixAmount": "0",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 99
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "22545AB87A7B1499682B746415FE7521",
                "packageKey": "callrecorder:1m",
                "period": "1m",
                "price": {
                  "amount": "29.99",
                  "prefixAmount": "29",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 2999
                },
                "payPermissionType": 1
              }
            },
            {
              "type": "membership",
              "id": "E19B85026B0BAB2CD369F628788C0E54",
              "packageKey": "callrecorder:trial_6m",
              "period": "3d",
              "price": {
                "amount": "0.99",
                "prefixAmount": "0",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 99
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "1899E10A78E6ED94EBD8D63E3BD8ED92",
                "packageKey": "callrecorder:6m",
                "period": "6m",
                "price": {
                  "amount": "69.99",
                  "prefixAmount": "69",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 6999
                },
                "payPermissionType": 1
              }
            },
            {
              "type": "membership",
              "id": "27B097EB1DEF013C665E54A3D7BB7E80",
              "packageKey": "callrecorder:1w",
              "period": "1w",
              "price": {
                "amount": "14.99",
                "prefixAmount": "14",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 1499
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "27B097EB1DEF013C665E54A3D7BB7E80",
                "packageKey": "callrecorder:1w",
                "period": "1w",
                "price": {
                  "amount": "14.99",
                  "prefixAmount": "14",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 1499
                },
                "payPermissionType": 1
              }
            },
            {
              "type": "membership",
              "id": "22545AB87A7B1499682B746415FE7521",
              "packageKey": "callrecorder:1m",
              "period": "1m",
              "price": {
                "amount": "29.99",
                "prefixAmount": "29",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 2999
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "22545AB87A7B1499682B746415FE7521",
                "packageKey": "callrecorder:1m",
                "period": "1m",
                "price": {
                  "amount": "29.99",
                  "prefixAmount": "29",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 2999
                },
                "payPermissionType": 1
              }
            },
            {
              "type": "membership",
              "id": "1899E10A78E6ED94EBD8D63E3BD8ED92",
              "packageKey": "callrecorder:6m",
              "period": "6m",
              "price": {
                "amount": "69.99",
                "prefixAmount": "69",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 6999
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "1899E10A78E6ED94EBD8D63E3BD8ED92",
                "packageKey": "callrecorder:6m",
                "period": "6m",
                "price": {
                  "amount": "69.99",
                  "prefixAmount": "69",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 6999
                },
                "payPermissionType": 1
              }
            },
            {
              "type": "membership",
              "id": "132DD05F61EDF98AE849B780E840509E",
              "packageKey": "callrecorder:limited_6m",
              "period": "6m",
              "price": {
                "amount": "34.99",
                "prefixAmount": "34",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 3499
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "132DD05F61EDF98AE849B780E840509E",
                "packageKey": "callrecorder:limited_6m",
                "period": "6m",
                "price": {
                  "amount": "34.99",
                  "prefixAmount": "34",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 3499
                },
                "payPermissionType": 1
              }
            },
            {
              "type": "membership",
              "id": "3C7A1590E966FC480933251033E40D61",
              "packageKey": "callrecorder:99y",
              "period": "99y",
              "price": {
                "amount": "89.99",
                "prefixAmount": "89",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 8999
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "3C7A1590E966FC480933251033E40D61",
                "packageKey": "callrecorder:99y",
                "period": "99y",
                "price": {
                  "amount": "89.99",
                  "prefixAmount": "89",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 8999
                },
                "payPermissionType": 1
              }
            },
            {
              "type": "membership",
              "id": "884D06FCE02A46475F3FD7F9FEF148FB",
              "packageKey": "callrecorder:limited_99y",
              "period": "99y",
              "price": {
                "amount": "44.99",
                "prefixAmount": "44",
                "suffixAmount": "99",
                "currency": "USD",
                "currencySymbol": "$",
                "currencyPosition": "prefix",
                "amountInt": 4499
              },
              "payPermissionType": 1,
              "packageForRepeat": {
                "type": "membership",
                "id": "884D06FCE02A46475F3FD7F9FEF148FB",
                "packageKey": "callrecorder:limited_99y",
                "period": "99y",
                "price": {
                  "amount": "44.99",
                  "prefixAmount": "44",
                  "suffixAmount": "99",
                  "currency": "USD",
                  "currencySymbol": "$",
                  "currencyPosition": "prefix",
                  "amountInt": 4499
                },
                "payPermissionType": 1
              }
            }
          ],
          "paymentMethodList": {"CreditCard": {"availableCardTypes": ["Visa", "MasterCard"]}},
          "bonusForPackage": [],
          "one-click": {"token": "11EC25223B32831BA8E252732F4B14CD", "description": "4111 **** **** 1111"},
          "requestValidationToken": "615b14364eb4c9.2360796440852",
          "packageType": "membership"
        }
      }
    }
  },

  async mounted() {
    await this.getPackages();
    if (this.limitedPackageFromStore && !localStorage.getItem('from-error-page')) {
      this.selectedPackage = this.limitedPackageFromStore;
    } else if (this.declinedPackageFromStore) {
      this.selectedPackage = this.declinedPackageFromStore;
    }

    localStorage.removeItem('error-page');
  },
  computed: {
    menuOpened() {
      return store.menuOpened;
    },
    computedPackagesList() {
      return this.group && this.packagesList.length
          ? this.packagesList
              .filter(el => this.availablePackages[this.group].includes(el.packageKey))
              .sort((a, b) => {
                const orderA = this.packagesAdditionalInfo.find(e => e.key === a.packageKey).order;
                const orderB = this.packagesAdditionalInfo.find(e => e.key === b.packageKey).order;

                return (orderA > orderB) ? 1 : ((orderB > orderA) ? -1 : 0);
              })
          : [];
    },
    computedDefaultPackage() {
      return this.packagesList.find(el => el.packageKey === this.defaultPackages[this.group]);
    },
    computedDefaultPackageAdditionalInfo() {
      return this.packagesAdditionalInfo.find(el => el.key === this.computedDefaultPackage.packageKey);
    },
    computedLimitedPackage() {
      return this.payload.packageList.find(el => {
        return el.packageKey.includes('limited') && el.packageKey.includes(this.computedDefaultPackage.period);
      });
    },
    computedSelectedFullPackage() {
      return this.payload.packageList.find(el => {
        const period = this.selectedPackage.packageKey.split('_')[1] || this.selectedPackage.period;
        return !el.packageKey.includes('trial') && el.packageKey.includes(period);
      })
    },
    computedSelectedFullPackageCode() {
      return this.packagesAdditionalInfo.find(el => el.key === this.computedSelectedFullPackage.packageKey).code;
    },
    computedAmplitudePackageEventName() {
      const amplitudeEventPrefix = this.isSelectedPackageLimited ? 'lto_' : '';
      return amplitudeEventPrefix + this.computedSelectedFullPackageCode.toLowerCase();
    },
    isSelectedPackageTrial() {
      return this.selectedPackage.packageKey.includes('trial');
    },
    isSelectedPackageLimited() {
      return this.selectedPackage.packageKey.includes('limited');
    },
    limitedPackageFromStore() {
      return store.limitedPackage;
    },
    declinedPackageFromStore() {
      return store.declinedPackage;
    },
    mainBtnText() {
      return 'Get now';
    }
  },
  watch: {
    group(val) {
      localStorage.setItem('experimentalGroup', val);
    },
    payload(data) {
      if (data) {
        this.packagesList = data.packageList;
      }
    },
    packagesList(data) {
      if (data && data.length) {
        localStorage.setItem('limitedPackageName', this.computedDefaultPackageAdditionalInfo.name);
      }
    },
    selectedPackage(data) {
      this.packageSelectedFlag = !!data;

      if (!data) {
        setTimeout(() => {
          const el = this.$refs.packagesList;
          if (el) el.scrollIntoView({behavior: 'smooth'});
        }, 0);
      }
    }
  },
  methods: {
    // filter / find / set
    onGetNowBtnClick() {
      let packageItem = this.computedPackagesList.find((item) => item.packageKey === this.selectedPlan)
      this.selectedPlan = packageItem.packageKey;
      if (!this.trialAccepted) return false;
      let preSelectedPackage = this.getTrialPackage(packageItem);
      if (!preSelectedPackage) {
        preSelectedPackage = packageItem;
      }

      this.selectedPackage = preSelectedPackage;
    },
    onPackageClick(packageItem) {
      this.selectedPlan = packageItem.packageKey;
      if (!this.trialAccepted) return false;
      let preSelectedPackage = this.getTrialPackage(packageItem);
      if (!preSelectedPackage) {
        preSelectedPackage = packageItem;
      }

      // this.selectedPackage = preSelectedPackage;
    },
    getTrialPackage(packageItem) {
      return this.payload.packageList.find(el => {
        return el.packageKey.includes('trial') && el.packageKey.includes(packageItem.period)
      })
    },
    getPackageProperty(product, property) {
      let currentPackage = this.packagesAdditionalInfo.find(el => el.key === product.packageKey);
      if (!currentPackage) {
        currentPackage = this.computedSelectedFullPackage;
      }

      return currentPackage[property];
    },

    async getPackages() {
      // await axios.get('/payment/page-api/getPaymentPageData/membership').then(response => {
      //   this.payload = response.data.payload;
      //   this.loading = false;
      // });

      this.payload = this.mockProducts.payload;
    },
  }
}
</script>

<style lang="scss" scoped>
.premium-page::v-deep {
  padding: 20px 0 100px;

  .loading {
    position: relative;
    background: #e6e6e6 !important;
    color: #e6e6e6 !important;

    img {
      opacity: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-25%);
      background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
      animation: loading 3s infinite;
    }

    @keyframes loading {
      100% {
        transform: translateX(25%);
      }
    }
  }

  .container {
    display: flex;
    max-width: none;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .page-content {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 30px 55px;
    border-radius: 3px;
    text-align: center;
    margin: 0 auto;

    .title {
      margin-top: 25px;
      font-size: 80px;
      font-weight: 600;
    }

    .subtitle {
      margin: 15px 0;
      font-size: 28px;
    }

    .membership-btn {
      width: 250px;
      padding: 15px 25px;
      background: #07A78A;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border: 0;
      border-radius: 30px;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
      }
    }

    .content-title {
      max-width: 323px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      margin: 0 auto 36px;
    }

    .membership-title-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      > div {
        padding: 0 30px;
      }

      .membership-left {
        display: flex;
        flex-direction: column;

        .membership-title {
          text-align: left;
          font-size: 38px;
          font-weight: 600;
        }

        .membership-desc {
          margin: 20px 0 50px;
          text-align: left;
          font-size: 20px;
          max-width: 320px;
        }
      }

      .membership-img img {
        width: 400px;
      }
    }

    .packages-wrapper {
      //margin: 50px auto;
      width: 450px;

      .packages-list {
        margin-bottom: 25px;

        .package {
          position: relative;
          display: flex;
          padding: 5px 10px;
          margin-bottom: 15px;
          align-items: center;
          justify-content: space-between;
          background: #fff;
          border: 1px solid #CFCFCF;
          border-radius: 16px;
          cursor: pointer;
          filter: drop-shadow(0 3px 12px #ddeee2);

          &.disabled {
            opacity: 0.5;
          }

          &:hover {
            box-shadow: 2px 4px 12px rgba(24, 25, 29, .3)
          }

          &.popular {
            padding-top: 25px;
            //border: 2px solid #5EA96C;

            .label {
              position: absolute;
              display: block;
              width: 100%;
              padding: 2px 0;
              left: 0;
              top: 0;
              color: #fff;
              background: #07A78A;
              font-size: 13px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              text-transform: uppercase;
            }
          }

          &.selected {
            border: 1px solid #07A78A;

            .checkbox {
              transform: rotate(45deg);
              height: 27px;
              width: 15px;
              border-bottom: 5px solid #07A78A;
              border-right: 5px solid #07A78A;
              margin: -5px 8px 0 8px;
            }
          }

          &:not(.selected) {
            .checkbox {
              width: 30px;
              height: 30px;
              background-color: #fff;
              color: #e3e3e6;
              border-radius: 20px;
              border: 1px solid #bcbcc6;
            }
          }

          .label {
            display: none;
          }

          .packages__radio {
            position: fixed;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: -1;
          }

          .package-info-wrapper {
            width: 65%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .package-info {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .name {
                font-size: 20px;
                font-weight: 500;
                color: #615E5E;
              }

              .previous-week-price {
                position: relative;
                font-weight: 500;

                &:after {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 37%;
                  color: #f67272;
                  width: 100%;
                  border-bottom: 2px solid #f67272;
                  transform: rotate(-15.52deg);
                }
              }
            }

            .package-price {
              color: #BCBCC6;
              text-align: left;

              span {
                margin-right: 5px;
              }
            }
          }

          .current-week-price {
            position: relative;
            color: #605D5D;
            font-size: 12px;

            img {
              height: 50px;
              width: 100px;
            }

            .info {
              position: absolute;
              display: flex;
              top: 7px;
              left: 20px;

              .dollars-per-day {
                display: flex;
                margin-right: 3px;
                align-items: flex-end;
                font-size: 30px;
                font-weight: 700;
                line-height: 36px;
                color: #4c4948;
              }

              .per-day-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                &.flex-start {
                  justify-content: flex-start !important;
                }
              }

              .coins-per-day {
                font-size: 13px;
              }
            }
          }
        }
      }

      .accepting-conditions_container {
        margin: 25px 0 125px;
        position: relative;
        display: flex;
        justify-content: center;
        text-align: left;
        cursor: pointer;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark {
            background-color: #fff;
          }

          &:checked ~ .checkmark:after {
            display: block;
          }

          ~ .checkmark {
            background-color: #fff;
            border: 1px solid rgba(31, 27, 26, 0.8);
            border-radius: 3px;
            width: 12px;
            height: 12px;
          }
        }

        .checkmark {
          margin-right: 10px;
          margin-top: 1px;
          flex: 0 0 12px;

          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
      }

      .accepting-conditions_container .checkmark:after {
        margin-left: 2px;
        width: 4px;
        height: 5px;
        border: solid black;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .acceptingConditions {
        color: #A1A1A1;
        font-size: 13px;
        text-align: left;

        p {
          margin-top: 15px;
        }

        a {
          color: #07A78A;
          text-decoration: none;
        }
      }
    }
  }
}

/* Media queries */
@media (max-width: 1279px) {
  .premium-page {
    padding: 0;

    .page-content {
      max-width: 100%;
      padding: 0 0 20px;
      border: none;
    }
  }
}

@media (max-width: 768px) {
  .page-content {
    .title {
      margin-top: 0 !important;
      font-size: 32px !important;
      font-weight: 500 !important;
    }

    .subtitle {
      font-size: 22px !important;
    }

    .package-price {
      .additional-text {
        display: block;
      }
    }
  }

  .membership-title-wrapper {
    flex-direction: column;
    margin-top: 30px;

    .membership-title {
      font-weight: 500 !important;
    }

    .membership-desc {
      max-width: none !important;
      font-size: 18px !important;
    }

    .membership-title, .membership-desc {
      text-align: center !important;
    }

    .membership-btn {
      display: none;
    }

    .membership-img {
      img {
        width: 100% !important;
      }
    }
  }

  .packages-wrapper {
    width: 100% !important;

    .package-info-wrapper {
      width: 52% !important;
    }
  }

  .accepting-conditions_container {
    margin-bottom: 50px !important;
  }
}

@media (max-width: 325px) {
  .packages-wrapper {
    .packages-list {
      .package {
        .package-info-wrapper {
          width: 45% !important;
        }

        .name {
          font-size: 14px !important;
        }

        .previous-week-price {
          font-size: 13px !important;
        }
      }
    }
  }
}
</style>
