import Vue from 'vue';

export const store = Vue.observable({
    menuOpened: false,
    limitedPackage: null,
    fullPackage: null,
    declinedPackage: null,
    footerGetNowState: false
})

export const mutations = {
    setMenuState(bool) {
        store.menuOpened = bool;
    },
    setLimitedPackage(limitedPackage) {
        store.limitedPackage = limitedPackage;
    },
    setFullPackage(fullPackage) {
        store.fullPackage = fullPackage;
    },
    setDeclinedPackage(declinedPackage) {
        store.declinedPackage = declinedPackage;
    },
    setFooterGetNowState(footerGetNowState) {
        store.footerGetNowState = footerGetNowState;
    }
}