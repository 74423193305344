<template>
  <div style="margin: auto">
    <div class="payment-form-wrapper">
      <div class="close-form-btn">
        <img src="@/assets/img/icons/ic_close.svg" alt="" @click="goToLimitedOffer">
      </div>
<!--      <div class="cards-logo">-->
<!--        <img src="@/assets/img/card_brands/visa-secure.svg" alt="">-->
<!--        <img src="@/assets/img/card_brands/mastercard-secure.svg" alt="">-->
<!--        <img src="@/assets/img/card_brands/maestro.svg" alt="">-->
<!--      </div>-->

      <div class="paypal-btn-wrapper">
        <button class="paypal-btn" :disabled="computedDisabledPayBtnFlag" @click="submit"></button>
      </div>

      <h1 class="form-option-container"><span class="form-option">or</span></h1>

      <div class="form-control">
        <label for="card-number">Card Number</label>
        <img :src="`../../public/img/card_brands/${computedCardBrandName}.svg`" class="input-card-image" alt="">
        <input
            type="tel"
            v-model="form.cardNumber.value"
            id="card-number"
            class="app-input card-input"
            :class="{'invalid': showValidationMessage && form.cardNumber.error}"
            placeholder="XXXX XXXX XXXX XXXX"
            @input="onCardNumberInput"
        />
        <div v-if="showValidationMessage && form.cardNumber.error" class="invalid-message">
          {{ form.cardNumber.error }}
        </div>
      </div>
      <div class="card-security-wrapper">
        <div class="form-control">
          <label for="expire-date">Card expiry</label>
          <div class="expire-group">
            <input
                type="tel"
                v-model="form.expire.value"
                id="expire-date"
                class="app-input card-input"
                :class="{'invalid': showValidationMessage && form.expire.error}"
                placeholder="MM/YY"
                @input="onExpireDateInput"
            />
            <div v-if="showValidationMessage && form.expire.error"
                 class="invalid-message pos-left">{{ form.expire.error }}
            </div>
          </div>
        </div>
        <div class="form-control">
          <label for="expire-date">CVV/CVC</label>
          <div class="expire-group">
            <img
                src="@/assets/img/icons/info-black.svg"
                class="cvv-info-icon"
                alt=""
                @click="showInfoTooltip = !showInfoTooltip"
            >
            <div v-if="showInfoTooltip" class="info-tooltip">
              <div class="three">
                <span>3 on back</span>
                <img src="@/assets/img/icons/card-with-line.svg" alt="">
              </div>
              <div class="four">
                <span>4 on front</span>
                <img src="@/assets/img/icons/card-without-line.svg" alt="">
              </div>
            </div>
            <input
                type="password"
                v-model="form.cvc.value"
                id="cvc"
                class="app-input card-input cvv-input"
                :class="{'invalid': showValidationMessage && form.cvc.error}"
                placeholder="CVV2"
                pattern="[0-9]*"
                inputmode="numeric"
                @input="onCvcInput"
            />
            <div v-if="showValidationMessage && form.cvc.error"
                 class="invalid-message pos-left">{{ form.cvc.error }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-control">
        <label for="card-number">Card Holder Name</label>
        <input
            type="text"
            v-model="form.name.value"
            id="card-holder-name"
            class="app-input card-input"
            :class="{'invalid': showValidationMessage && form.name.error}"
            placeholder="Name on card"
            @input="onCardholderNameInput"
        />
        <div v-if="showValidationMessage && form.name.error"
             class="invalid-message pos-left">{{ form.name.error }}
        </div>
      </div>
      <div class="form-control">
        <label for="card-number">Email</label>
        <input
            type="text"
            v-model="form.email.value"
            id="email"
            class="app-input card-input"
            :class="{'invalid': showValidationMessage && form.email.error}"
            placeholder="Email"
            @input="onEmailInput"
        />
        <div v-if="showValidationMessage && form.email.error"
             class="invalid-message pos-left">{{ form.email.error }}
        </div>
      </div>

      <div v-if="isTrialSelected" class="total-wrapper">
        <div class="today-price">
          <div>Total today</div>
          <div>After 3-day trial</div>
        </div>
        <div class="usual-price">
          <div>{{ `${selectedPackage.price.currencySymbol} ${selectedPackage.price.amount}` }}</div>
          <div>{{ `${fullPackage.price.currencySymbol} ${fullPackage.price.amount}` }}</div>
        </div>
      </div>
      <div v-else class="total-wrapper">
        <div>Total</div>
        <div>{{ `${selectedPackage.price.currencySymbol} ${selectedPackage.price.amount}` }}</div>
      </div>

      <div class="pay-btn-wrapper">
        <button class="pay-btn" :disabled="computedDisabledPayBtnFlag" @click="submit">Pay</button>
      </div>
      <label class="accepting-conditions_container">
        <input class="accepting-conditions-input" type="checkbox" v-model="acceptTermsFlag">
        <span class="checkmark"></span>
        <span class="acceptingConditions">
          Accepting
      <a href="https://app-fasting.com/terms-of-use" target="_blank">Terms of Use</a>
          and
         <a href="https://app-fasting.com/privacy-policy" target="_blank">Privacy Policy</a>
        </span>
      </label>

      <div class="payment-desc-msg">Charges will appear on your credit card statement as Zemeto Limassol</div>
    </div>

    <div class="text-muted pay-help-msg">
      Pay safe and secure
    </div>
    <div class="cards-wrapper">
      <div class="cards-logo-wrapper">
        <img src="@/assets/img/card_brands/visa.svg" alt="">
        <img src="@/assets/img/card_brands/mastercard.svg" alt="">
        <img src="@/assets/img/card_brands/maestro-notext.svg" alt="">
        <img src="@/assets/img/card_brands/pci-dss-compliant-logo-vector.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import CreditCardType from 'credit-card-type';

export default {
  props: {
    selectedPackage: {
      required: true
    },
    fullPackage: {
      required: true
    },
    planName: {
      required: true
    },
  },
  data: () => ({
    form: {
      cardNumber: {
        value: '',
        error: ''
      },
      cvc: {
        value: '',
        pattern: /^\d{3,4}$/,
        error: ''
      },
      expire: {
        value: '',
        error: ''
      },
      name: {
        value: '',
        error: ''
      },
      email: {
        value: '',
        pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        error: ''
      }
    },
    showValidationMessage: false,
    acceptTermsFlag: false,
    okSubmitFlag: false,
    showInfoTooltip: false
  }),
  mounted() {
    this.form.email.value = this.currentEmail;
    this.scrollToTop();
  },
  computed: {
    computedDisabledPayBtnFlag() {
      return !this.acceptTermsFlag || this.okSubmitFlag;
    },
    computedCardNumber() {
      return this.form.cardNumber.value;
    },
    computedCardType() {
      const types = CreditCardType(this.form.cardNumber.value);
      return types.length && types.length === 1 ? types[0] : [];
    },
    computedCardBrandName() {
      let brand;

      switch (this.computedCardType.type) {
        case 'mastercard':
          brand = 'mastercard';
          break;
        case 'visa':
          brand = 'visa';
          break;
        case 'discover':
          brand = 'discover';
          break;
        case 'american-express':
          brand = 'amex';
          break;
        case 'jcb':
          brand = 'jcb';
          break;
        case 'payoneer':
          brand = 'payoneer';
          break;
        case 'paypal':
          brand = 'paypal';
          break;
        case 'maestro':
          brand = 'maestro';
          break;
        default:
          brand = 'unknown';
          break;
      }

      return brand;
    },
    isTrialSelected() {
      return this.selectedPackage.packageKey.includes('trial');
    }
  },
  methods: {
    onCardNumberInput() {
      this.form.cardNumber.value = this.form.cardNumber.value.replace(/[\D,\s]/g, '');
      this.showValidationMessage = false;
      let segments = this.form.cardNumber.value.match(/.{1,4}/g);
      if (segments) this.form.cardNumber.value = segments.slice(0, 6).join(' ');
    },
    onExpireDateInput() {
      let value = this.form.expire.value;
      this.form.expire.value = value.replace(/[^0-9/]/g, '').substr(0, 5);

      // slash validation
      if (value.charAt(value.length - 1) === '/' && value.length !== 3) {
        this.form.expire.value = value.substr(0, value.length - 1);
      }

      const month = this.form.expire.value.split('/')[0];
      if (month.length === 2 && parseInt(month) < 1) this.form.expire.value = '01';
      if (parseInt(month) > 12) this.form.expire.value = '12';
      if (this.form.expire.value.length === 2) this.form.expire.value += '/';
      this.showValidationMessage = false;
    },
    onCvcInput() {
      this.form.cvc.value = this.form.cvc.value.replace(/[\D,\s]/g, '').substr(0, 4)
      this.showValidationMessage = false;
      this.validateCvc();
    },
    onCardholderNameInput() {
      this.form.name.value = this.form.name.value.replace(/[^a-zA-Z ]/gi, '').toUpperCase();
      this.showValidationMessage = false;
      this.validateCardHolderName();
    },
    onEmailInput() {
      this.showValidationMessage = false;
      this.validateEmail();
    },
    validateCardNumber() {
      this.form.cardNumber.error = '';
      const trimmedCardNumber = this.form.cardNumber.value.replace(/[^0-9]/g,'');

      if (!trimmedCardNumber.length) {
        this.form.cardNumber.error = 'Card number can\'t be blank'
      } else if ((!this.computedCardType.lengths && trimmedCardNumber.length < 12)
          || (this.computedCardType.lengths && !this.computedCardType.lengths.includes(trimmedCardNumber.length))) {
        this.form.cardNumber.error = 'Card number is invalid';
      }
    },
    validateCvc() {
      this.form.cvc.error = ''
      if (!this.form.cvc.value.length) {
        this.form.cvc.error = 'CVC can\'t be blank';
      } else if (!this.form.cvc.pattern.test(this.form.cvc.value)) {
        this.form.cvc.error = 'CVC is invalid'
      }
    },
    validateCardHolderName() {
      this.form.name.error = ''
      if (!this.form.name.value.length) {
        this.form.name.error = 'Name can\'t be blank'
      } else if (this.form.name.value.trim().length < 2) {
        this.form.name.error = 'Card Holder Name can\'t be less then 2 symbols'
      }
    },
    validateEmail() {
      this.form.email.error = '';
      if (!this.form.email.value.length) {
        this.form.email.error = 'Email can\'t be blank'
      } else if (!this.form.email.pattern.test(this.form.email.value)) {
        this.form.email.error = 'Email is invalid'
      }
    },
    validateFormFields() {
      this.validateCardNumber();
      this.isExpireDateValid();
      this.validateCvc();
      this.validateCardHolderName();
      this.validateEmail();
    },
    checkFormValidation() {
      for (let field of Object.values(this.form)) {
        if (field.error) return false
      }
      return true
    },
    generateFormData() {
      return {
        ccPan: this.form.cardNumber.value.replace(/\s/g, ''),
        ccCvv: this.form.cvc.value,
        ccExp: this.form.expire.value,
        ccName: this.form.name.value,
        email: this.form.email.value
      }
    },
    submit() {
      this.validateFormFields()
      if (this.checkFormValidation()) {
        this.okSubmitFlag = true;
        this.$emit('submit', this.generateFormData());
      } else {
        this.okSubmitFlag = false;
        this.showValidationMessage = true;
      }
    },
    isExpireDateValid() {
      // Check 2-2 digits
      if (!/\d\d\/\d\d/.test(this.form.expire.value)) {
        this.form.expire.error = 'Invalid date format'
        return false;
      }

      // Check month is 1 to 12 inclusive
      let b = this.form.expire.value.split('/');
      if (b[0] < 1 || b[0] > 12) {
        this.form.expire.error = 'Expiry month must be from 01 to 12'
        return false;
      }

      // Check is this month or later
      let d = new Date()
      let c = d.getFullYear() / 100 | 0 + '';
      if (new Date(c + b[1], b[0], 1) < d) {
        this.form.expire.error = 'Expiry date must be this month or later'
        return false;
      }

      this.form.expire.error = null;
      return true;
    },
    goToLimitedOffer() {
      this.$router.go(0);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-form-wrapper {
  width: 500px;
  padding: 20px;
  border-radius: 30px;
  background: #fff;

  .close-form-btn {
    display: flex;
    justify-content: flex-end;

    img {
      width: 25px;
      cursor: pointer;
    }
  }



  .form-option-container{
    text-align: center;
    overflow: hidden;

  }

  .form-option-container > .form-option {
    position: relative;
    padding: 0 5px;
    display: inline-block;
    font-size: 17px;
    color: #1F1B1A;
  }
  .form-option-container > .form-option:before, .form-option-container > .form-option:after {
    content:"";
    width: 240px;
    border-top: 2px solid rgba(9, 9, 11, 0.08);
    position: absolute;
    right: 100%;
    top: 50%;
    margin-top: -2px;
  }
  .form-option-container > .form-option:after {
    left: 100%;
  }




  .cards-logo {
    display: flex;
    padding: 25px;
    margin-bottom: 25px;
    align-items: center;
    justify-content: space-around;

    img {
      width: 50px;
    }
  }

  .form-control {
    position: relative;

    .input-card-image {
      position: absolute;
      left: 15px;
      top: 45px;
    }
  }

  .card-security-wrapper {
    display: flex;
    justify-content: space-between;

    .form-control {
      flex-basis: 100%;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .form-control {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 10px;
    }

    .cvv-info-icon {
      position: absolute;
      top: 45px;
      right: 10px;
      cursor: pointer;
    }

    .info-tooltip {
      padding: 15px 20px;
      position: absolute;
      top: 75px;
      right: 0;
      display: flex;
      background: #fff;
      border-radius: 16px;
      z-index: 1;
      box-shadow: 0 3px 12px rgba(24, 25, 29, 0.09);

      &:before {
        content: "";
        position: absolute;
        top: -15px;
        right: 15px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
      }

      .three, .four {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 0;
        align-items: center;
        font-size: 12px;

        span {
          margin-bottom: 10px;
          white-space: nowrap;
        }
      }

      .three {
        margin-right: 25px;
      }
    }

    .app-input {
      height: 55px;
      padding: 15px;
      border: 1px solid #dcdcdc;
      box-shadow: 0 3px 12px rgba(24, 25, 29, 0.09);
      border-radius: 16px;

      &.card-input {
        width: 100%;
      }

      &.invalid {
        border: 1px solid #F67272;
      }
    }

    #card-number {
      padding-left: 55px;
    }
  }

  .total-wrapper {
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;

    .today-price, .usual-price {
      div:last-child {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .usual-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .paypal-btn-wrapper{
    padding-top: 20px;
    .paypal-btn{
      width: 100%;
      height: 49px;
      background: #FED32C;
      background-image: url("../assets/img/paypal_buynow.svg");
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      border-radius: 16px;
      &:hover{
        cursor: pointer;
      }
    }
  }

  .pay-btn {
    width: 100%;
    padding: 15px 25px;
    background: #07A78A;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: 0;
    border-radius: 30px;
    cursor: pointer;

    &[disabled] {
      opacity: 0.6;
    }
  }

  .accepting-conditions_container {
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-top: 15px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: #fff;
      }

      &:checked ~ .checkmark:after {
        display: block;
      }

      ~ .checkmark {
        background-color: #fff;
        border: 1px solid rgba(31, 27, 26, 0.8);
        border-radius: 3px;
        width: 12px;
        height: 12px;
      }
    }

    .checkmark {
      margin-right: 15px;
      margin-top: 2px;
      height: 25px;
      width: 25px;

      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }

  .accepting-conditions_container .checkmark:after {
    margin-left: 2px;
    width: 4px;
    height: 5px;
    border: solid black;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .acceptingConditions {
    a {
      color: #07A78A;
      text-decoration: none;
    }
  }

  .payment-desc-msg {
    margin-top: 15px;
    padding: 0 50px;
    color: #858588;
    font-size: 14px;
    text-align: center;
  }
}

.text-muted {
  color: #858588;
}

.pay-help-msg {
  margin: 20px 0;
  text-align: center;
  font-size: 14px;
}

.cards-logo-wrapper {
  display: flex;
  padding: 0 65px;
  margin: 10px 0;
  align-items: center;
  justify-content: space-around;

  img {
    width: 40px;
  }
}

.invalid-message {
  margin-top: 7px;
  color: #F67272;
  font-size: 14px;
}

@media (max-width: 768px) {
  .cards-wrapper {
    margin-bottom: 50px;
  }

  .card-security-wrapper {
    .form-control {
      flex-basis: 100%;

      .cvv-info-icon {
        top: 43px;
      }
    }
  }

  .payment-desc-msg {
    padding: 0 !important;
  }
}

@media (max-width: 414px) {
  .payment-form-wrapper {
    width: 100%;
  }

  .cards-logo-wrapper {
    padding: 0 30px !important;
  }
}
</style>
